import React from 'react';

import Grid, { Col } from 'Components/Grid';
import {
    Container,
    Heading,
    Link,
    List,
    ListItem,
    Wrapper,
    AnchorIcon
} from './InPageNavigation.styled';

import { LinkProps } from 'Elements/Link';

export interface LinkWithId extends LinkProps {
    id: string;
}

export interface Props {
    heading?: string;
    headingType?: 'h2' | 'h3';
    links: LinkWithId[];
    headingColor?: React.CSSProperties['color'];
    //LinkColor Provides a way to colour all links instead of having to do it for each in map
    linkColor?: React.CSSProperties['color'];
    backgroundColor?: React.CSSProperties['backgroundColor'];
    id: string;
}

const InPageNavigation: React.FC<Props> = ({
    links,
    heading,
    headingType = 'h2',
    backgroundColor,
    headingColor,
    linkColor,
    id
}) => {
    return (
        <Wrapper
            style={
                {
                    '--bg-color': backgroundColor,
                    '--heading-color': headingColor,
                    '--link-color': linkColor
                } as React.CSSProperties
            }
            $heading={heading}
            id={id}
        >
            <Grid>
                {heading && (
                    <Col>
                        <Heading as={headingType}>{heading}</Heading>
                    </Col>
                )}
                <Col>
                    <Container>
                        <nav aria-label="Page anchor link navigation">
                            <List>
                                {links.map(link => (
                                    <ListItem key={link.id}>
                                        <AnchorIcon />
                                        <Link {...link} />
                                    </ListItem>
                                ))}
                            </List>
                        </nav>
                    </Container>
                </Col>
            </Grid>
        </Wrapper>
    );
};

export default InPageNavigation;

import styled, { css } from 'styled-components';

export const Container = styled.div`
    ${({ theme }) => css`
        position: relative;
        overflow: hidden;

        @media ${theme.breakpoints.large.media} {
            min-height: 19rem;
        }
    `}
`;
export const Background = styled.div`
    ${({ theme }) => css`
        display: none;

        @media ${theme.breakpoints.large.media} {
            display: flex;
            align-items: center;
            height: 100%;
            position: absolute;
            right: 0;
            bottom: 0;
            top: 0;
            z-index: 0;
        }
    `}
`;

export const Filter = styled.div`
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    background: linear-gradient(to right, white 40%, rgba(255, 255, 255, 0));
    z-index: 1;
`;

export const CopyContainer = styled.div`
    position: relative;
    z-index: 2;
`;

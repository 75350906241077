import React, { useEffect } from 'react';
import Script from 'next/script';

import { Wrapper } from './Carousel.styled';
import Grid, { Col } from 'Components/Grid';

import { H2 } from 'Elements/Typography';

export interface Props {
    id: string;
    heading: string;
    headingType?: 'h2' | 'h3';
    columnStart?: number;
    columnEnd?: number;
}

const Carousel: React.FC<Props> = ({ id, heading, headingType = 'h2', columnEnd, columnStart }) => {
    useEffect(() => {
        window.unibuddySettings = {
            universitySlug: 'southampton-solent-university',
            ubLang: 'en-GB',
            ubCookieConsent: 'necessary'
        };
    }, []);

    return (
        <>
            <Script
                type="text/javascript"
                src="https://cdn.unibuddy.co/unibuddy-carousel.js"
                async
                defer
                id="unibuddy-carousel-script"
            />
            <Wrapper id={id} background>
                <Grid>
                    <Col l={columnStart && columnEnd && [columnStart, columnEnd]}>
                        <H2 as={headingType}>{heading}</H2>
                        <div data-unibuddy-carousel />
                    </Col>
                </Grid>
            </Wrapper>
        </>
    );
};

export default Carousel;

import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    ${({ theme }) => css`
        position: relative;
        display: flex;
        width: 100%;
        overflow: hidden;
        z-index: 1;
        flex-direction: column;
        background-color: var(--bg-color, ${theme.colors.accents[5].hex});
    `}
`;

export const Content = styled.div`
    ${({ theme }) => css`
        width: 100%;
        padding: 1rem 0rem 1.5rem;
        z-index: 2;
        background-color: var(--bg-color, ${theme.colors.accents[5].hex});
        position: relative;
        color: ${theme.colors.secondary.hex};
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media ${theme.breakpoints.medium.media} {
            padding: 1rem 1.5rem 2.75rem 0;
        }

        @media ${theme.breakpoints.large.media} {
            grid-row: 1;
            padding: 5.5rem 2.5rem 5.5rem 0;
            width: 50%;
        }
    `}
`;

const headingCSS = theme => css`
    font-family: ${theme.fonts.secondary};
    font-size: 2rem;
    font-weight: 300;
    line-height: 1.2;
    color: ${theme.colors.primaryContrast.hex};

    @media ${theme.breakpoints.large.media} {
        font-size: 2.5rem;
    }
`;

export const Heading = styled.h2`
    ${({ theme }) => css`
        ${headingCSS(theme)}
        margin: 0;
    `}
`;

export const InnerContent = styled.div`
    ${({ theme }) => css`
        && {
            a {
                color: ${theme.colors.primaryContrast.hex};
            }
        }
    `}
`;

export const ImageWrapper = styled.div`
    ${({ theme }) => css`
        position: relative;
        width: 100%;
        grid-column: 1;
        grid-row: 1;
        z-index: -1;
        height: 15rem;

        @media ${theme.breakpoints.medium.media} {
            height: 20rem;
        }

        @media ${theme.breakpoints.large.media} {
            width: 50%;
            height: auto;
        }
    `}
`;

export const Container = styled.div`
    display: flex;
`;

export const MobileWrapper = styled.div`
    ${({ theme }) => css`
        display: block;

        @media ${theme.breakpoints.large.media} {
            display: none;
        }
    `}
`;

export const DesktopWrapper = styled.div`
    ${({ theme }) => css`
        position: relative;
        display: none;

        @media ${theme.breakpoints.large.media} {
            display: block;
        }
    `}
`;

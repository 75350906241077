import React from 'react';

import { LandingComposerProps } from 'Common/util/cmsComposer/FlexibleLandingPage';

import Introduction from 'Modules/Banner/Information/Introduction';
import InformationBanner from 'Modules/Banner/Information';
import CourseSearch from 'Modules/PromoPoint/CourseSearch';
import GeneralPromoPoint from 'Modules/PromoPoint/General';
import ContactUs from 'Modules/OpenDays/Section/ContactUs';
import GridNavigation from 'Modules/GridNavigation';
import Flexible from 'Modules/KeyStats/Flexible';
import VirtualTour from 'Modules/VirtualTour';
import LinkCards from 'Modules/Cards/Link';
import MiniCards from 'Modules/Cards/Mini';
import Brand from 'Modules/Banner/Brand';
import InformationGrid from 'Modules/InformationGrid';
import TefAward from 'Modules/PromoPoint/TefAward';

import Unibuddy from 'Components/Unibuddy';
import UnibuddyCarousel from 'Components/Unibuddy/Carousel';
import TextBlockWave from 'Components/TextBlock/Wave';
import ImageCaption from 'Components/ImageCaption';
import PageDivider from 'Components/PageDivider';
import QuoteBlock from 'Components/QuoteBlock';
import Accordion from 'Components/Accordion';
import Grid, { Col } from 'Components/Grid';
import LineList from 'Components/List/LineList';
import Table from 'Components/Table';
import Video from 'Components/Video';
import Block from 'Components/Block';
import IframeGecko from 'Components/IframeGecko';
import Box from 'Elements/Box';

import { H2 } from 'Elements/Typography';
import Arrow from 'Elements/Link/Arrow';

import { Wrapper, StyledBlock, MobileHidden } from './Landing.styled';

/**
 * Add names of components here where margin-tops are not required.
 **/

const marginTopRuleset = [
    'introBlock',
    'generalPromoPointWithWave',
    'bannerBrand',
    'twoColumnTextBlock',
    'textBlockWithCourseSearch',
    'textWithKeyFacts',
    'virtualTour',
    'geckoForm',
    'unibuddyCarousel',
    'unibuddyMain'
];

const FlexibleLandingBlockRender = (content: LandingComposerProps[]): React.ReactNode => {
    return content.map((item, index) => (
        <React.Fragment key={index}>
            {item.inPageAnchorId && <div id={item.inPageAnchorId} />}
            {item.quoteFlexibleLanding && (
                <Wrapper $paddingBottom={index + 1 === content.length ? 1 : undefined}>
                    <Grid>
                        <Col l={[3, 11]}>
                            <QuoteBlock {...item.quoteFlexibleLanding} />
                        </Col>
                    </Grid>
                </Wrapper>
            )}
            {item.flexibleGridNavigation && (
                <Wrapper $paddingBottom={index + 1 === content.length ? 1 : undefined}>
                    <Grid>
                        <Col>
                            {item.flexibleGridNavigation.heading && (
                                <H2>{item.flexibleGridNavigation.heading}</H2>
                            )}
                            <GridNavigation {...item.flexibleGridNavigation} />
                        </Col>
                    </Grid>
                </Wrapper>
            )}
            {item.informationBannerWithImage && (
                <Wrapper $paddingBottom={index + 1 === content.length ? 1 : undefined}>
                    <Grid>
                        <Col>
                            <InformationBanner {...item.informationBannerWithImage} />
                        </Col>
                    </Grid>
                </Wrapper>
            )}
            {item.textBlockWithVideo && (
                <Wrapper
                    $paddingBottom={index + 1 === content.length ? 1 : undefined}
                    $paddingTop={index === 0 ? 1 : undefined}
                >
                    <Grid rowGap={'1rem'}>
                        <Col l={6} order={item.textBlockWithVideo.order}>
                            <H2>{item.textBlockWithVideo.heading}</H2>
                            {item.textBlockWithVideo.text}
                            {item.textBlockWithVideo.link && (
                                <Arrow {...item.textBlockWithVideo.link} />
                            )}
                        </Col>
                        <Col l={6}>
                            <MobileHidden>
                                <Box p={1} />
                            </MobileHidden>
                            <Video {...item.textBlockWithVideo.video} />
                        </Col>
                    </Grid>
                </Wrapper>
            )}
            {item.twoColumnTextWithAccordion && (
                <Wrapper
                    $paddingBottom={index + 1 === content.length ? 1 : undefined}
                    $paddingTop={index === 0 ? 1 : undefined}
                >
                    <Grid rowGap={'1rem'}>
                        <Col l={6} order={item.twoColumnTextWithAccordion.order}>
                            <H2>{item.twoColumnTextWithAccordion.heading}</H2>
                            {item.twoColumnTextWithAccordion.text}
                            {item.twoColumnTextWithAccordion.link && (
                                <Arrow {...item.twoColumnTextWithAccordion.link} />
                            )}
                        </Col>
                        <Col l={6}>
                            {item.twoColumnTextWithAccordion.accordion.map(accordion => (
                                <Accordion key={accordion.id} {...accordion} />
                            ))}
                        </Col>
                    </Grid>
                </Wrapper>
            )}
            {item.textBlockWithBulletPointList && (
                <Wrapper
                    $paddingBottom={index + 1 === content.length ? 1 : undefined}
                    $paddingTop={index === 0 ? 1 : undefined}
                >
                    <Grid rowGap={'2rem'}>
                        <Col l={6} order={item.textBlockWithBulletPointList.order}>
                            <H2>{item.textBlockWithBulletPointList.heading}</H2>
                            {item.textBlockWithBulletPointList.text}
                            {item.textBlockWithBulletPointList.link && (
                                <Arrow {...item.textBlockWithBulletPointList.link} />
                            )}
                        </Col>
                        <Col l={6}>
                            <LineList {...item.textBlockWithBulletPointList.list} />
                        </Col>
                    </Grid>
                </Wrapper>
            )}
            {item.twoColumnTextBlock && (
                <Wrapper
                    $backgroundColor={item.twoColumnTextBlock.backgroundColour}
                    $paddingTop={item.twoColumnTextBlock.backgroundColour !== undefined ? 1 : 0}
                    $paddingBottom={item.twoColumnTextBlock.backgroundColour !== undefined ? 1 : 0}
                    $noMarginTop={
                        index !== 0 &&
                        item.twoColumnTextBlock.backgroundColour !== undefined &&
                        marginTopRuleset.includes(Object.keys(content[index - 1])[0])
                    }
                >
                    <Grid rowGap={'2rem'}>
                        <Col l={6}>
                            <StyledBlock
                                style={{
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    padding: `${
                                        item.twoColumnTextBlock.backgroundColour === '#E8E9EA'
                                            ? undefined
                                            : 0
                                    }`
                                }}
                            >
                                <H2>{item.twoColumnTextBlock.headingColumnOne}</H2>
                                {item.twoColumnTextBlock.textColumnOne}
                                {item.twoColumnTextBlock.linkColumnOne && (
                                    <Arrow
                                        {...item.twoColumnTextBlock.linkColumnOne}
                                        style={{ marginTop: 'auto' }}
                                    />
                                )}
                            </StyledBlock>
                        </Col>
                        <Col l={6}>
                            <StyledBlock
                                style={{
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    padding: `${
                                        item.twoColumnTextBlock.backgroundColour === '#E8E9EA'
                                            ? undefined
                                            : 0
                                    }`
                                }}
                            >
                                <H2>{item.twoColumnTextBlock.headingColumnTwo}</H2>
                                {item.twoColumnTextBlock.textColumnTwo}
                                {item.twoColumnTextBlock.linkColumnTwo && (
                                    <Arrow
                                        {...item.twoColumnTextBlock.linkColumnTwo}
                                        style={{ marginTop: 'auto' }}
                                    />
                                )}
                            </StyledBlock>
                        </Col>
                    </Grid>
                </Wrapper>
            )}
            {item.textBlockWithCourseSearch && (
                <Wrapper
                    $noMarginTop={
                        index !== 0 && marginTopRuleset.includes(Object.keys(content[index - 1])[0])
                    }
                >
                    <CourseSearch {...item.textBlockWithCourseSearch} />
                </Wrapper>
            )}
            {item.textBlockWithImage && (
                <Wrapper
                    $paddingBottom={index + 1 === content.length ? 1 : undefined}
                    $paddingTop={index === 0 ? 1 : undefined}
                >
                    <Grid rowGap={'2rem'}>
                        <Col l={6} order={item.textBlockWithImage.order}>
                            <H2>{item.textBlockWithImage.heading}</H2>
                            {item.textBlockWithImage.text}
                            {item.textBlockWithImage.link && (
                                <Arrow {...item.textBlockWithImage.link} />
                            )}
                        </Col>
                        <Col l={6}>
                            <MobileHidden>
                                <Box p={1} />
                            </MobileHidden>
                            <ImageCaption
                                {...item.textBlockWithImage.imageCaption}
                                borderRadius={true}
                            />
                        </Col>
                    </Grid>
                </Wrapper>
            )}
            {item.accordion && (
                <Wrapper
                    $paddingBottom={index + 1 === content.length ? 1 : undefined}
                    $paddingTop={index === 0 ? 1 : undefined}
                >
                    <Grid>
                        <Col l={8}>
                            {item.accordion.heading && <H2>{item.accordion.heading}</H2>}
                            {item.accordion.items.map(accordion => (
                                <Accordion key={accordion.id} {...accordion} />
                            ))}
                        </Col>
                    </Grid>
                </Wrapper>
            )}
            {item.table && (
                <Wrapper
                    $paddingBottom={index + 1 === content.length ? 1 : undefined}
                    $paddingTop={index === 0 && item.table.heading === undefined ? 1 : undefined}
                >
                    <Grid>
                        <Col>
                            <Table {...item.table} />
                        </Col>
                    </Grid>
                </Wrapper>
            )}
            {item.bannerBrand && (
                <Wrapper
                    $noMarginTop={
                        index !== 0 && marginTopRuleset.includes(Object.keys(content[index - 1])[0])
                    }
                >
                    <Brand {...item.bannerBrand} />
                </Wrapper>
            )}
            {item.introBlock && (
                <Wrapper
                    $noMarginTop={
                        index !== 0 && marginTopRuleset.includes(Object.keys(content[index - 1])[0])
                    }
                >
                    <Introduction {...item.introBlock} />
                </Wrapper>
            )}
            {item.generalPromoPointWithWave && (
                <Wrapper>
                    <GeneralPromoPoint {...item.generalPromoPointWithWave} />
                </Wrapper>
            )}
            {item.tefAward && (
                <Wrapper>
                    <TefAward {...item.tefAward} />
                </Wrapper>
            )}
            {item.pageDivider && (
                <Wrapper>
                    <PageDivider {...item.pageDivider} />
                </Wrapper>
            )}
            {item.squareGridPromoCardsWrapper && (
                <Wrapper $paddingBottom={index + 1 === content.length ? 1 : undefined}>
                    <LinkCards {...item.squareGridPromoCardsWrapper} />
                </Wrapper>
            )}
            {item.iconList && (
                <Wrapper $paddingBottom={index + 1 === content.length ? 1 : undefined}>
                    {item.iconList.list.heading && (
                        <Grid>
                            <Col>
                                <H2>{item.iconList.list.heading}</H2>
                            </Col>
                        </Grid>
                    )}
                    <Grid>
                        <Col l={7}>
                            <Block>
                                <LineList {...item.iconList.list} />
                            </Block>
                        </Col>
                    </Grid>
                </Wrapper>
            )}
            {item.textWithKeyFacts && (
                <Wrapper
                    $noMarginTop={
                        index !== 0 && marginTopRuleset.includes(Object.keys(content[index - 1])[0])
                    }
                >
                    <Flexible {...item.textWithKeyFacts} />
                </Wrapper>
            )}
            {item.virtualTour && (
                <Wrapper $paddingBottom={index + 1 === content.length ? 'none' : 0.5}>
                    <VirtualTour {...item.virtualTour} />
                </Wrapper>
            )}
            {item.meetTheTeam && (
                <Wrapper $paddingBottom={index + 1 === content.length ? 1 : undefined}>
                    {item.meetTheTeam.heading && (
                        <Grid>
                            <Col>
                                <H2>{item.meetTheTeam.heading}</H2>
                            </Col>
                        </Grid>
                    )}
                    <Grid>
                        <Col>
                            <MiniCards {...item.meetTheTeam.cards} />
                        </Col>
                    </Grid>
                </Wrapper>
            )}
            {item.textBlockWave && (
                <Wrapper $paddingBottom={index + 1 === content.length ? 1 : undefined}>
                    <TextBlockWave {...item.textBlockWave} />
                </Wrapper>
            )}
            {item.singleVideo && (
                <Wrapper $paddingBottom={index + 1 === content.length ? 1 : undefined}>
                    <Grid>
                        <Col l={6}>
                            {item.singleVideo.heading && <H2>{item.singleVideo.heading}</H2>}
                            <Video {...item.singleVideo.embed} />
                        </Col>
                    </Grid>
                </Wrapper>
            )}
            {item.twoVideoSection && (
                <Wrapper $paddingBottom={index + 1 === content.length ? 1 : undefined}>
                    <Grid>
                        <Col>
                            {item.twoVideoSection.heading && (
                                <H2>{item.twoVideoSection.heading}</H2>
                            )}
                        </Col>
                    </Grid>
                    <Grid rowGap={'1rem'}>
                        <Col l={6}>
                            <Video {...item.twoVideoSection.leftColumnVideo} />
                        </Col>
                        <Col l={6}>
                            <Video {...item.twoVideoSection.rightColumnVideo} />
                        </Col>
                    </Grid>
                </Wrapper>
            )}
            {item.iconPromoPointWave && (
                <Wrapper
                    $noMarginTop={
                        index !== 0 && marginTopRuleset.includes(Object.keys(content[index - 1])[0])
                    }
                    $overflow="hidden"
                >
                    <ContactUs {...item.iconPromoPointWave} />
                </Wrapper>
            )}

            {item.tripleIconPromo && (
                <Wrapper $paddingBottom={index + 1 === content.length ? 1 : undefined}>
                    <InformationGrid {...item.tripleIconPromo} />
                </Wrapper>
            )}
            {item.geckoForm && (
                <Wrapper
                    $noMarginTop={
                        index !== 0 && marginTopRuleset.includes(Object.keys(content[index - 1])[0])
                    }
                >
                    <IframeGecko {...item.geckoForm} />
                </Wrapper>
            )}
            {item.unibuddyMain && (
                <Wrapper
                    $noMarginTop={
                        index !== 0 && marginTopRuleset.includes(Object.keys(content[index - 1])[0])
                    }
                >
                    <Unibuddy {...item.unibuddyMain} />
                </Wrapper>
            )}
            {item.unibuddyCarousel && (
                <Wrapper
                    $noMarginTop={
                        index !== 0 && marginTopRuleset.includes(Object.keys(content[index - 1])[0])
                    }
                >
                    <UnibuddyCarousel {...item.unibuddyCarousel} />
                </Wrapper>
            )}
        </React.Fragment>
    ));
};

export default FlexibleLandingBlockRender;

import React from 'react';

import UniBuddy, { UniBuddyPopCardProps } from 'Common/util/uniBuddy';

import { LandingComposerProps } from 'Common/util/cmsComposer/FlexibleLandingPage';

import Herobanner, { MakeWavesHeroBannerProps } from 'Modules/HeroBanner/MakeWaves';
import InPageNavigation, { InPageNavigationProps } from 'Modules/InPageNavigation';

import Breadcrumbs, { BreadcrumbsProps } from 'Components/Breadcrumbs';
import Main from 'Elements/Main';

import { FlexibleLandingBlockRenderer } from 'Templates/Flexible/Landing';

export interface Props {
    breadcrumbs: BreadcrumbsProps;
    heroBanner: MakeWavesHeroBannerProps;
    inPageNavigation: InPageNavigationProps;
    content: LandingComposerProps[];
    uniBuddyPopcard: { hide: boolean } & UniBuddyPopCardProps;
}

const Landing: React.FC<Props> = props => {
    const { breadcrumbs, heroBanner, content, inPageNavigation, uniBuddyPopcard } = props;

    return (
        <>
            <Breadcrumbs {...breadcrumbs} />
            <Herobanner {...heroBanner} />
            <Main>
                {inPageNavigation.links.length >= 1 && <InPageNavigation {...inPageNavigation} />}
                {FlexibleLandingBlockRenderer(content)}
                {!uniBuddyPopcard.hide &&
                    UniBuddy({
                        filterValue: uniBuddyPopcard.filterValue
                            ? uniBuddyPopcard.filterValue
                            : 'All'
                    })}
            </Main>
        </>
    );
};

export default Landing;

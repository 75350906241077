import React from 'react';
import { useTheme } from 'styled-components';

import CardLink, { CardLinkProps } from 'Components/Card/Link';
import Grid, { Col } from 'Components/Grid';

import { List, Heading } from './Link.styled';

export interface Props {
    heading?: string;
    cards: CardLinkProps[];
}

const Link: React.FC<Props> = ({ cards, heading }) => {
    const { colors } = useTheme();
    const colours = [
        colors.accents[3].hex,
        colors.accents[6].hex,
        colors.accents[4].hex,
        colors.accents[8].hex,
        colors.accents[1].hex
    ];

    return (
        <Grid>
            <Col>
                {heading && (
                    <Col>
                        <Heading>{heading}</Heading>
                    </Col>
                )}
                <List>
                    {cards.map((card, index) => (
                        <li key={index}>
                            <CardLink {...card} textColor={colours[index % colours.length]} />
                        </li>
                    ))}
                </List>
            </Col>
        </Grid>
    );
};

export default Link;

import styled, { css } from 'styled-components';
import { animated } from '@react-spring/web';
import scrollbarStyle from 'Common/styles/scrollbar';
import Icon from 'Elements/Icon';

export const Heading = styled.h3`
    margin: 1rem 0;
`;

export const Container = styled.div<{ $heading?: string }>`
    ${({ $heading }) => css`
        transform: translateZ(0);

        ${!$heading &&
        css`
            margin-top: 2rem;
        `}
    `}
`;

export const TableWrapper = styled.div`
    ${({ theme }) => css`
        border-radius: 0.4rem;
        border: ${theme.commonStyles.brandPixel} solid ${theme.colors.tertiary.hex};
        overflow-x: auto;
        scrollbar-width: 1.6rem;
        -ms-overflow-style: none;

        &::-webkit-scrollbar {
            display: none;
        }

        @media ${theme.breakpoints.medium.media} {
            padding-bottom: 0.2rem;
            scrollbar-width: 1.6rem;
            -ms-overflow-style: auto;
            ${scrollbarStyle()}

            &::-webkit-scrollbar {
                display: block;
            }
        }
    `}
`;

export const Row = styled.div`
    ${({ theme }) => css`
        display: flex;

        ${Cell} {
            background: ${theme.colors.background.hex};
        }

        &:nth-of-type(2n) {
            ${Cell} {
                background: ${theme.colors.secondary.tints[0].hex};
            }
        }

        &:first-of-type {
            ${Cell} {
                margin-top: 2rem;
            }
        }

        @media ${theme.breakpoints.medium.media} {
            &:first-of-type {
                ${Cell} {
                    margin-top: 0rem;
                }
            }
        }
    `}
`;

export const HeadingRow = styled(Row)`
    ${({ theme }) => css`
        position: relative;
        background: ${theme.colors.tertiary.hex};

        ${Cell} {
            background: ${theme.colors.tertiary.hex};
        }

        &:first-of-type {
            ${Cell} {
                margin-top: 0;
            }
        }
    `}
`;

export const Cell = styled.div`
    ${({ theme }) => css`
        display: flex;
        align-items: center;
        padding: 0.75rem;
        flex: 1 0 40%;
        font-size: 0.8125rem;
        line-height: 1.35;

        &:first-of-type {
            border-left: none;
        }

        @media ${theme.breakpoints.medium.media} {
            padding: 1rem;
            flex: 1 0 22.5%;
            font-size: 1.125rem;
        }
    `}
`;

export const HeadingCell = styled(Cell)`
    ${({ theme }) => css`
        font-size: 0.875rem;
        font-weight: 700;
        padding: 1rem;
        color: ${theme.colors.primaryContrast.hex};

        @media ${theme.breakpoints.medium.media} {
            font-size: 1.25rem;
        }
    `}
`;

export const Slider = styled.div`
    ${({ theme }) => css`
        height: 0.1rem;
        padding-top: 1.15rem;
        background: ${theme.colors.secondary.tints[2].hex};
        position: fixed;
        height: 1.5rem;
        display: flex;
        align-items: center;
    `}
`;

export const SliderControl = styled(animated.div)`
    ${({ theme }) => css`
        font-size: 0.75rem;
        text-transform: uppercase;
        background-color: ${theme.colors.tertiary.hex};
        border-radius: 0.75rem;
        color: white;
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        transform: translateY(-50%);
        position: relative;
        cursor: default;
    `}
`;

export const StyledIcon = styled(Icon)`
    ${({ theme }) => css`
        width: 0.8rem;
        height: 0.8rem;
        margin: 0.2rem 0;
        color: ${theme.colors.secondary.tints[0].hex};
        margin-right: 0.5rem;
    `}
`;

export const StyledIconReverse = styled(StyledIcon)`
    transform: scaleX(-1);
    margin-left: 0.5rem;
`;

import styled, { css } from 'styled-components';
import { animated } from '@react-spring/web';

export const BasicKeyStat = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: auto 15%;

        @media ${theme.breakpoints.large.media} {
            margin: auto;
        }

        @media ${theme.breakpoints.huge.media} {
            margin: auto 15%;
        }
    `}
`;

export const Circle = styled.div`
    ${({ theme }) => css`
        position: relative;
        background-color: var(--basic-bg-color, white);
        color: var(--basic-txt-color, ${theme.colors.tertiary.hex});
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 10.5rem;
        width: 10.5rem;
        border-radius: 50%;
        padding: 0.3rem 0.8rem 0.8rem;

        @media ${theme.breakpoints.medium.media} {
            height: 12.5rem;
            width: 12.5rem;
        }
    `}
`;

export const Stat = styled(animated.span)`
    ${({ theme }) => css`
        color: inherit;
        border-radius: 50%;
        font-weight: 700;
        font-size: 2rem;
        line-height: 1.25;

        @media ${theme.breakpoints.medium.media} {
            font-size: 2.5rem;
        }

        @media ${theme.breakpoints.large.media} {
            font-size: 2.375rem;
        }
    `}
`;

export const Information = styled(animated.div)`
    ${({ theme }) => css`
        text-align: center;
        font-weight: light;
        font-size: 0.875rem;
        color: var(--basic-txt-color, ${theme.colors.tertiary.hex});

        @media ${theme.breakpoints.medium.media} {
            font-size: 1rem;
        }
    `}
`;

export const NumberContainer = styled(animated.span)`
    ${({ theme }) => css`
        line-height: 1;
        position: relative;
        font-size: 3rem;

        @media ${theme.breakpoints.medium.media} {
            font-size: 3.625rem;
        }
    `}
`;

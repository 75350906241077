import React from 'react';

import { LinkProps } from 'Elements/Link';
import { Chevron } from '@solent-university/solent-icons';

import { Wrapper, CardLink, IconStyled, Container } from './Link.styled';

export interface Props {
    id?: string;
    link: LinkProps;
    backgroundColor?: React.CSSProperties['backgroundColor'];
    textColor?: React.CSSProperties['color'];
}

const Link: React.FC<Props> = ({ link, backgroundColor, textColor, id }) => {
    return (
        <Container
            id={id}
            style={
                {
                    '--bg-color': backgroundColor,
                    '--text-color': textColor
                } as React.CSSProperties
            }
        >
            <CardLink {...link} />
            <Wrapper>
                <IconStyled icon={Chevron} />
            </Wrapper>
        </Container>
    );
};

export default Link;

import React from 'react';
import NextImage, { StaticImageData } from 'next/image';

import ArrowLink, { ArrowLinkProps } from 'Elements/Link/Arrow';

import { Container, Content, InnerContent, ImageWrapper, Heading } from './Information.styled';

export interface Props {
    image: {
        src: StaticImageData | string;
        alt?: string;
    };
    color?: string;
    heading: string;
    children?: string | React.ReactNode;
    link?: ArrowLinkProps;
}

const Information: React.FC<Props> = props => {
    const { image, children, heading, color, link } = props;
    return (
        <Container>
            <Content>
                <Heading $color={color}>{heading}</Heading>
                <InnerContent $color={color}>{children}</InnerContent>
                {link && <ArrowLink variant={'threeDark'} {...link} />}
            </Content>
            <ImageWrapper>
                <NextImage
                    src={image.src}
                    alt={image.alt || ''}
                    fill
                    style={{ objectFit: 'cover' }}
                />
            </ImageWrapper>
        </Container>
    );
};

export default Information;

import type { PageEntry, Node, Entry } from 'contensis-delivery-api';
import cmsParser from 'Common/util/cmsParser';

import { composerFlexibleLandingPage } from 'Common/util/cmsComposer';

import type { Props } from './Landing';

export type PageData = {
    entry: PageEntry;
    ancestors: Node[];
    courses: Entry[];
};

const datamap = (data: PageData): Props => {
    const { entry, ancestors, courses } = data;

    const { title, sys, heroBannerWaveDefault, pageElements, inPageNavigation } = entry;

    return {
        breadcrumbs: cmsParser.breadcrumbs([...ancestors, { entry: { title: title }, id: sys.id }]),
        heroBanner: {
            ...cmsParser.heroBannerWave(heroBannerWaveDefault),
            oneColumn: true
        },
        inPageNavigation: {
            id: 'in-page-navigation',
            heading: 'Information included on this page',
            links: inPageNavigation.map((link, index) => ({
                label: link.linkName,
                ariaLabel: link.linkName,
                href: `#${link.inPageNavigationID}`,
                id: `inPageNavigation-${index}`
            }))
        },
        content: composerFlexibleLandingPage(pageElements, courses),
        uniBuddyPopcard: {
            hide: entry.unibuddyMetadata?.hideUnibuddy,
            filterValue: entry.unibuddyMetadata?.courseLevelFilter
        }
    };
};

export default datamap;

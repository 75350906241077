import React, { useState, useEffect } from 'react';
import Script from 'next/script';

export interface Props {
    filterValue?: 'Postgraduate' | 'Undergraduate' | 'All';
}

// providing All as a filterValue showcases ALL active ambassadors in a randomised order

const UniBuddy: React.FC<Props> = ({ filterValue }) => {
    const [loadUniBuddy, setLoadUniBuddy] = useState(false);

    useEffect(() => {
        setTimeout(function () {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (window as any).unibuddySettings = {
                // eslint-disable-next-line camelcase
                uni_id: 'southampton-solent-university',
                colour: 'E30613',
                domain: 'https://popcard.unibuddy.co/',
                title: 'Unibuddy Popcard',
                align: 'left',
                ubLang: 'en-GB',
                ubCookieConsent: 'necessary',
                filterKey: filterValue === 'All' ? undefined : 'level',
                filterValue: filterValue === 'All' ? undefined : filterValue
            };
            setLoadUniBuddy(true);
        }, 50000);
    }, [filterValue]);

    return loadUniBuddy ? (
        <>
            <Script
                id="unibuddy-popcard-script"
                src="https://cdn.unibuddy.co/unibuddy-popcard.js"
            />
        </>
    ) : null;
};

export default UniBuddy;

import styled, { css } from 'styled-components';
import BaseLink from 'Elements/Link';
import { Link as AnchorLink } from '@solent-university/solent-icons';

export const Wrapper = styled.section<{ $heading?: string }>`
    ${({ theme, $heading }) => css`
        background-color: var(--bg-color, ${theme.colors.primaryContrast.hex});
        padding: 1.5rem 0 1.25rem;

        @media ${theme.breakpoints.large.media} {
            padding: 1.25rem 0 0.4rem;
        }

        ${!$heading &&
        css`
            border-radius: 0.25rem;
            padding: 0.5rem 0 0;

            @media ${theme.breakpoints.large.media} {
                padding: 1rem 0 0;
            }
        `}
    `}
`;

export const Container = styled.div`
    ${({ theme }) => css`
        @media ${theme.breakpoints.large.media} {
            display: flex;
        }
    `}
`;

export const Heading = styled.h2`
    ${({ theme }) => css`
        font-size: 1.125rem;
        line-height: 1.2;
        margin: 0 0 1rem 0;
        color: var(--heading-color, ${theme.colors.tertiary.hex});

        @media ${theme.breakpoints.medium.media} {
            font-size: 1.25rem;
        }
    `}
`;

export const Link = styled(BaseLink)`
    ${({ theme }) => css`
        color: var(--link-color, ${theme.colors.text.hex});

        :after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
    `}
`;

export const List = styled.ul`
    ${({ theme }) => css`
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        margin: inherit;
        flex-direction: row;

        @media ${theme.breakpoints.large.media} {
            margin: 0;
        }
    `}
`;

export const ListItem = styled.li`
    ${({ theme }) => css`
        display: flex;
        align-items: center;
        position: relative;
        list-style: none;
        list-style-type: none;
        padding: 0 1.25rem 0.5rem;

        @media ${theme.breakpoints.medium.media} {
            padding-bottom: 0.5rem;
        }

        @media ${theme.breakpoints.large.media} {
            margin: 0;
            padding-bottom: 1.25rem;
        }
    `}
`;

export const AnchorIcon = styled(AnchorLink)`
    width: 1.5rem;
    height: 1.5rem;
    stroke-width: 0.1rem;
    color: var(--link-color, ${({ theme }) => theme.colors.primary.hex});
    margin-right: 0.5rem;
`;

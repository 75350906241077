import React from 'react';
import NextImage from 'next/image';

import ArrowLink, { ArrowLinkButtonProps } from 'Elements/Button/Arrow';

import Grid, { Col } from 'Components/Grid';

import Wave from 'Public/graphics/waves/brand-banner/brand-banner-wave.png';

import { Container, Wrapper, Background, Heading, Text } from './Brand.styled';

export interface Props {
    heading: string;
    text: string;
    link: ArrowLinkButtonProps;
    backgroundColor?: string;
    textColor?: string;
}

const Brand: React.FC<Props> = ({ link, text, heading, backgroundColor, textColor }) => {
    return (
        <Wrapper
            style={
                {
                    '--bg-color': backgroundColor,
                    '--text-color': textColor
                } as React.CSSProperties
            }
        >
            <Container>
                <Background>
                    <NextImage src={Wave} alt="" fill style={{ objectFit: 'cover' }} />
                </Background>
                <Grid>
                    <Col m={[3, 11]} l={[3, 11]}>
                        <Heading>{heading}</Heading>
                        <Text>{text}</Text>
                        <ArrowLink {...link} />
                    </Col>
                </Grid>
            </Container>
        </Wrapper>
    );
};

export default Brand;

import React from 'react';
import { Megaphone } from '@solent-university/solent-icons';

import { ArrowLinkButtonProps } from 'Elements/Button/Arrow';

import Grid, { Col } from 'Components/Grid';
import SocialIcons, { SocialIconsProps } from 'Components/SocialIcons';

import {
    Container,
    StyledIcon,
    Heading,
    StyledLink,
    StyledArrowButton,
    SocialsWrapper,
    Wave
} from './ContactUs.styled';

export interface Props {
    id: string;
    heading: string;
    generalQuestions: {
        link: ArrowLinkButtonProps;
    };
    icon?: React.ElementType;
    socials?: SocialIconsProps;
    useWave?: boolean;
}

const ContactUs: React.FC<Props> = props => {
    const { id, heading, generalQuestions, icon, socials, useWave = true } = props;

    return (
        <Container id={id} background paddingTop={1} paddingBottom={1.5} align="center">
            <Grid>
                <Col l={[4, 10]}>
                    <StyledIcon as={icon ? icon : Megaphone} />
                    <Heading>{heading}</Heading>
                    {generalQuestions.link.href.includes('mailto:') ? (
                        <StyledLink
                            label={generalQuestions.link.href.replace('mailto:', '')}
                            href={generalQuestions.link.href}
                            ariaLabel={generalQuestions.link.ariaLabel}
                        />
                    ) : (
                        <StyledArrowButton {...generalQuestions.link} />
                    )}
                    {socials && (
                        <SocialsWrapper>
                            <SocialIcons {...socials} />
                        </SocialsWrapper>
                    )}
                </Col>
            </Grid>
            {useWave && <Wave />}
        </Container>
    );
};

export default ContactUs;

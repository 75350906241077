import styled, { css } from 'styled-components';

import { heading3 } from 'Global/Typography';

export const Container = styled.div<{ $backgroundColor?: string; $showBorder?: boolean }>`
    ${({ theme, $backgroundColor, $showBorder }) => css`
        padding: 1.5rem 1rem;
        background: ${$backgroundColor ? $backgroundColor : theme.colors.background.hex};
        border-radius: 0.5rem;
        position: relative;

        ${$showBorder &&
        css`
            border: 0.0625rem solid ${theme.colors.secondary.tints[2].hex};
        `}

        @media ${theme.breakpoints.medium.media} {
            padding: 1.5rem;
        }
    `}
`;

export const Heading = styled.h2<{ $headingStyle: 'h2' | 'h3' }>`
    ${({ $headingStyle, theme }) => css`
        ${$headingStyle === 'h3' && heading3}
        ${$headingStyle === 'h2' &&
        css`
            font-size: 1.75rem;

            @media ${theme.breakpoints.large.media} {
                font-size: 2rem;
            }
        `}
    `}
    line-height: 1.2;
    margin-top: 0;
    margin-bottom: 1rem;
`;

export const Content = styled.div`
    ${({ theme }) => css`
        & > *:last-child {
            margin-bottom: 0;
        }

        h3 {
            font-size: 1.75rem;
            line-height: 1.2;
            margin-top: 0;
            margin-bottom: 1rem;

            @media ${theme.breakpoints.large.media} {
                font-size: 2rem;
            }
        }
    `}
`;

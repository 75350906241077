import React, { MutableRefObject, useEffect, useRef } from 'react';

import Grid, { Col } from 'Components/Grid';

import {
    Container,
    Wave,
    ImageWrapper,
    IframeWrapper,
    HeadingWrapper,
    Heading,
    SubHeading,
    ContainerIframe
} from './IframeGecko.styled';

export interface Props {
    url: string;
    id: string;
    heading: string;
    subHeading?: string;
}

const IframeGecko: React.FC<Props> = props => {
    const { url, heading, subHeading, id } = props;

    const iframeRef = useRef() as MutableRefObject<HTMLDivElement>;

    const regex = new RegExp('https://app.geckoform.com/gecko-embed/form.js\\?[A-Za-z0-9=]+');
    const isScriptValid = regex.test(url);

    useEffect(() => {
        const script = document.createElement('script');
        script.id = id;
        script.src = url;
        script.defer = true;

        isScriptValid && iframeRef.current.appendChild(script);
    }, []);

    return isScriptValid ? (
        <Container>
            <ImageWrapper>
                <Wave />
            </ImageWrapper>
            <Grid>
                <Col m={[2, 12]} l={[3, 11]}>
                    <IframeWrapper>
                        <HeadingWrapper>
                            <Heading>{heading}</Heading>
                            {subHeading && <SubHeading>{subHeading}</SubHeading>}
                        </HeadingWrapper>
                        <ContainerIframe ref={iframeRef} />
                    </IframeWrapper>
                </Col>
            </Grid>
        </Container>
    ) : null;
};

export default IframeGecko;

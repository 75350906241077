import React, { useState, useEffect } from 'react';

import { Figure, ImageWrapper, StyledImage, Caption } from './ImageCaption.styled';

export interface Props {
    image: {
        src: string;
        alt?: string;
    };
    caption?: string;
    borderRadius?: boolean;
}

const ImageCaption: React.FC<Props> = props => {
    const { image, caption, borderRadius = true } = props;

    const [dimensions, setDimensions] = useState({ width: 16, height: 9 }); // 16:9 default

    useEffect(() => {
        const img = new Image();
        img.src = image.src;
        img.onload = () => {
            setDimensions({ width: img.width, height: img.height });
        };
    });

    return (
        <Figure>
            <ImageWrapper>
                <StyledImage
                    src={image.src}
                    alt={image.alt || ''}
                    width={dimensions.width}
                    height={dimensions.height}
                    $borderRadius={borderRadius}
                />
            </ImageWrapper>
            {caption && <Caption>{caption}</Caption>}
        </Figure>
    );
};

export default ImageCaption;

import React from 'react';
import NextImage, { StaticImageData } from 'next/image';

import ArrowLink from 'Elements/Link/Arrow';
import { LinkProps } from 'Elements/Link';

import Grid, { Col } from 'Components/Grid';

import {
    Wrapper,
    Container,
    Content,
    InnerContent,
    ImageWrapper,
    Heading,
    MobileWrapper,
    DesktopWrapper
} from './Introduction.styled';

export interface Props {
    image: {
        src: StaticImageData | string;
        alt?: string;
    };
    heading: string;
    children?: string | React.ReactNode;
    link?: LinkProps;
    backgroundColor?: React.CSSProperties['backgroundColor'];
}

const Introduction: React.FC<Props> = props => {
    const { image, children, heading, link, backgroundColor } = props;

    return (
        <Wrapper
            style={
                {
                    '--bg-color': backgroundColor
                } as React.CSSProperties
            }
        >
            <DesktopWrapper>
                <Grid>
                    <Col>
                        <Container>
                            <Content>
                                <Heading>{heading}</Heading>
                                <InnerContent>{children}</InnerContent>
                                {link && <ArrowLink variant="secondaryFive" {...link} />}
                            </Content>
                            <ImageWrapper>
                                <NextImage
                                    src={image.src}
                                    alt={image.alt || ''}
                                    fill
                                    style={{ objectFit: 'cover' }}
                                />
                            </ImageWrapper>
                        </Container>
                    </Col>
                </Grid>
            </DesktopWrapper>
            <MobileWrapper>
                <Grid>
                    <Col>
                        <Container>
                            <Content>
                                <Heading>{heading}</Heading>
                                <InnerContent>{children}</InnerContent>
                                {link && <ArrowLink variant="secondaryFive" {...link} />}
                            </Content>
                        </Container>
                    </Col>
                </Grid>
                <ImageWrapper>
                    <NextImage
                        src={image.src}
                        alt={image.alt || ''}
                        fill
                        style={{ objectFit: 'cover' }}
                    />
                </ImageWrapper>
            </MobileWrapper>
        </Wrapper>
    );
};

export default Introduction;

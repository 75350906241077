import styled, { css } from 'styled-components';

import { Container as ArrowLink } from 'Elements/Link/Arrow/Arrow.styled';

export const Container = styled.div`
    ${({ theme }) => css`
        padding: 3rem 0;
        text-align: center;
        position: relative;
        background-color: var(--bg-color, ${theme.colors.primary.hex});

        ${ArrowLink} {
            font-size: 1.5rem;
            font-weight: bold;
        }
    `}
`;

export const Text = styled.p`
    ${({ theme }) => css`
        color: var(--text-color, ${theme.colors.primaryContrast.hex});

        margin-bottom: 2rem;
    `}
`;

export const Background = styled.div`
    position: absolute;
    top: -14rem;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    transform: scale(1.5, 1.5);
`;

export const Heading = styled.h2`
    ${({ theme }) => css`
        margin: 0 0 1.5rem;
        color: var(--text-color, ${theme.colors.primaryContrast.hex});
    `}
`;

export const Wrapper = styled.div`
    overflow: hidden;
`;

import { useReducer, useCallback, MutableRefObject, useEffect } from 'react';

const useSliderScroll = (
    hostContainer: MutableRefObject<HTMLElement | null>
): {
    handleScroll: (e: React.UIEvent<HTMLElement>) => void;
    handleScrollThumbMouseDown: (e: React.MouseEvent) => void;
    handleTouchStart: (e: React.TouchEvent) => void;
    sliderTrackWidth: number;
    sliderWidth: number;
    scrollPosition: number;
} => {
    const [state, setState] = useReducer((state, newState) => ({ ...state, ...newState }), {
        sliderTrackWidth: 0,
        sliderWidth: 0,
        scrollPosition: 0,
        sliderThumbPosition: 0,
        isDragging: false
    });

    const handleScroll = (e: React.UIEvent<HTMLElement>) => {
        const { scrollLeft, scrollWidth, offsetWidth } = e.target as HTMLElement;

        let newPosition = (scrollLeft / scrollWidth) * offsetWidth;
        newPosition = Math.min(newPosition, offsetWidth - state.sliderWidth);
        setState({ scrollPosition: newPosition });
    };

    const handleScrollThumbMouseDown = useCallback(e => {
        e.preventDefault();
        e.stopPropagation();

        setState({ sliderThumbPosition: e.clientX, isDragging: true });
    }, []);

    const handleTouchStart = useCallback(e => {
        setState({ sliderThumbPosition: e.targetTouches[0].clientX, isDragging: true });
    }, []);

    const handleDocumentMouseUp = (e: MouseEvent) => {
        if (state.isDragging) {
            e.preventDefault();
            setState({ isDragging: false });
        }
    };

    const handleDocumentMouseMove = useCallback(
        e => {
            if (hostContainer.current && state.isDragging) {
                e.preventDefault();
                e.stopPropagation();
                const scrollHostElement = hostContainer.current;
                const { scrollWidth, offsetWidth } = scrollHostElement;

                const deltaX =
                    (e.clientX || e.targetTouches[0].clientX) - state.sliderThumbPosition;
                const percentage = (scrollWidth / offsetWidth) * deltaX;

                setState({
                    sliderThumbPosition: e.clientX || e.targetTouches[0].clientX,
                    scrollPosition: Math.min(
                        Math.max(0, state.scrollPosition + deltaX),
                        offsetWidth - state.sliderWidth
                    )
                });
                scrollHostElement.scrollLeft = Math.min(
                    scrollHostElement.scrollLeft + percentage,
                    scrollWidth - offsetWidth
                );
            }
        },
        [state.isDragging, state.sliderThumbPosition, state.sliderWidth, state.scrollPosition]
    );

    const handleResize = () => {
        if (hostContainer.current) {
            const { clientWidth, scrollWidth } = hostContainer.current;
            const slidePercentage = clientWidth / scrollWidth;
            setState({
                sliderWidth: slidePercentage * clientWidth,
                sliderTrackWidth: hostContainer.current.clientWidth
            });
        }
    };

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        document.addEventListener('mousemove', handleDocumentMouseMove);
        document.addEventListener('touchmove', handleDocumentMouseMove);
        document.addEventListener('mouseup', handleDocumentMouseUp);
        document.addEventListener('mouseleave', handleDocumentMouseUp);
        document.addEventListener('touchend', handleDocumentMouseUp);

        return () => {
            document.removeEventListener('mousemove', handleDocumentMouseMove);
            document.removeEventListener('touchmove', handleDocumentMouseMove);
            document.removeEventListener('mouseup', handleDocumentMouseUp);
            document.removeEventListener('mouseleave', handleDocumentMouseUp);
            document.removeEventListener('touchend', handleDocumentMouseUp);
        };
    }, [handleDocumentMouseMove, handleDocumentMouseUp]);

    return {
        handleScroll,
        handleScrollThumbMouseDown,
        handleTouchStart,
        sliderTrackWidth: state.sliderTrackWidth,
        sliderWidth: state.sliderWidth,
        scrollPosition: state.scrollPosition
    };
};

export default useSliderScroll;

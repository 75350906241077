import styled, { css } from 'styled-components';

import ArrowLink from 'Elements/Link/Arrow';

export const Wrapper = styled.section`
    ${({ theme }) => css`
        background-color: var(--flexible-bg-color, ${theme.colors.accents[5].hex});
        padding: 2rem 0;
        position: relative;

        @media ${theme.breakpoints.large.media} {
            padding: 2.5rem 0;
        }
    `}
`;

export const Container = styled.ul`
    ${({ theme }) => css`
        padding: 0;
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        height: 100%;

        li {
            :last-child {
                flex-grow: 1;
                width: 100%;
            }
        }

        @media ${theme.breakpoints.medium.media} {
            justify-content: space-around;

            li {
                :last-child {
                    flex-grow: unset;
                    width: unset;
                }
            }
        }

        @media ${theme.breakpoints.xlarge.media} {
            display: flex;
            justify-content: space-around;
            align-items: center;
            margin: 0;
        }
    `}
`;

export const Heading = styled.h2`
    ${({ theme }) => css`
        color: var(--flexible-stat-heading-color, ${theme.colors.secondary.hex});
        margin-top: 0;
    `}
`;

export const Text = styled.div`
    ${({ theme }) => css`
        font-size: 1.25rem;
        font-weight: 300;
        line-height: 1.675;
        color: var(--flexible-stat-txt-color, ${theme.colors.secondary.hex});
        max-width: 34rem;

        @media ${theme.breakpoints.xlarge.media} {
            font-size: 1.5rem;
        }
    `}
`;

export const Link = styled(ArrowLink)`
    ${({ theme }) => css`
        margin-bottom: 2rem;

        @media ${theme.breakpoints.large.media} {
            margin-bottom: 0;
        }
    `}
`;

import styled, { css } from 'styled-components';
import NextImage from 'next/image';

export const Figure = styled.figure`
    margin: 0;
    padding: 0;
    overflow: hidden;
`;

export const ImageWrapper = styled.div`
    position: relative;
`;

export const StyledImage = styled(NextImage)<{ $borderRadius: boolean }>`
    ${({ $borderRadius }) => css`
        max-width: 100%;
        height: auto;
        object-fit: contain;

        ${$borderRadius &&
        css`
            border-radius: 0.5rem;
            transform: translateZ(0);
        `}
    `}
`;

export const Caption = styled.figcaption`
    font-size: 1rem;
    font-weight: 700;
    margin-top: 0.25rem;
`;

import React from 'react';
import { useInView } from 'react-intersection-observer';
import Mustache from 'mustache';
import { useSpring } from '@react-spring/web';
import parse from 'html-react-parser';
import { KeyStatProps } from 'Components/KeyStat';

import { BasicKeyStat, Circle, Stat, Information, NumberContainer } from './Basic.styled';
import colors from 'Theme/lightTheme/colors';

export interface Props extends KeyStatProps {
    backgroundColor?: string;
    statColor?: string;
    textColor?: string;
}

const Basic: React.FC<Props> = props => {
    const { statValue, stat, description, backgroundColor, statColor, textColor } = props;
    const { ref, inView } = useInView({ threshold: 0.5, triggerOnce: true });

    /* Generated string using Mustache */
    const output = Mustache.render(stat, { number: '<number></number>' });

    /*  Animations */
    const animateNumber = useSpring({
        val: inView ? statValue : 0,
        opacity: inView ? 1 : 0,
        delay: 500,
        from: { val: 0 }
    });

    const animateState = useSpring({
        opacity: inView ? 1 : 0,
        color: statColor ? statColor : colors.tertiary.hex,
        config: { duration: 1000 }
    });

    const textStyle = useSpring({
        opacity: inView ? 1 : 0,
        transform: inView ? 'translateY(0px)' : 'translateY(50px)',
        color: textColor ? textColor : colors.tertiary.hex,
        delay: 500
    });

    return (
        <BasicKeyStat>
            <Circle
                style={
                    {
                        '--basic-bg-color': backgroundColor
                    } as React.CSSProperties
                }
            >
                <Stat ref={ref} style={animateState}>
                    {parse(output, {
                        replace: ({ name }) =>
                            name === 'number' && (
                                <NumberContainer>
                                    {animateNumber.val.to(val => Math.floor(val))}
                                </NumberContainer>
                            )
                    })}
                </Stat>
                <Information style={textStyle}>{description}</Information>
            </Circle>
        </BasicKeyStat>
    );
};

export default Basic;

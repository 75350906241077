import styled, { css } from 'styled-components';

import { ReactComponent as WaveSVG } from 'Public/graphics/waves/gecko-form/wave-gecko-form.svg';

export const Container = styled.div`
    ${({ theme }) => css`
        width: 100%;
        position: relative;
        color: ${theme.colors.primaryContrast.hex};
        background-color: ${theme.colors.secondary.tints[1].hex};
    `}
`;

export const Wave = styled(WaveSVG)`
    ${({ theme }) => css`
        position: absolute;
        z-index: 0;
        top: -7rem;
        right: -34rem;
        height: 27rem;

        @media ${theme.breakpoints.medium.media} {
            top: 10rem;
            right: -19rem;
            height: 27rem;
        }

        @media ${theme.breakpoints.large.media} {
            top: 9rem;
            right: 0;
            height: auto;
        }
    `}
`;

export const ImageWrapper = styled.div`
    ${({ theme }) => css`
        position: absolute;
        right: 0;
        bottom: 0;
        top: 0;
        z-index: 0;
        width: 100%;
        height: 27rem;
        background-color: ${theme.colors.tertiary.hex};
        overflow: hidden;
    `}
`;

export const IframeWrapper = styled.div`
    text-align: center;
`;

export const HeadingWrapper = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 3rem 0 1.5rem 0;
        width: 100%;

        @media ${theme.breakpoints.large.media} {
            padding: 4.5rem 0 2.5rem 0;
        }
    `}
`;

export const Heading = styled.h2`
    font-size: 2rem;
    margin: 0;
`;

export const SubHeading = styled.h3`
    font-size: 1rem;
`;

export const ContainerIframe = styled.div`
    ${({ theme }) => css`
        position: relative;
        width: 100%;
        background: ${theme.colors.primaryContrast.hex};
        padding: 1rem;
        margin-bottom: 2rem;
        border-radius: 0.5rem;
    `}
`;

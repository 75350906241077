import styled, { css } from 'styled-components';

import BaseLink from 'Elements/Link';
import BaseIcon from 'Elements/Icon/Border';

export const IconStyled = styled(BaseIcon)`
    width: 2.5rem;
    min-width: 2.5rem;
    height: 2.5rem;
    font-size: 1.5rem;
    color: var(--text-color, ${({ theme }) => theme.colors.accents[4].hex});
`;

export const Container = styled.div`
    ${({ theme }) => css`
        position: relative;
        background-color: var(--bg-color, ${theme.colors.tertiary.hex});
        padding: 1.5rem;
        border-radius: 0.5rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        transform: translateZ(0);

        ${IconStyled} {
            transition: all 300ms ease;
            transform: translateX(0rem);
        }

        @media ${theme.breakpoints.large.media} {
            flex-direction: column;
            align-items: flex-start;
        }

        &:hover {
            ${IconStyled} {
                transform: translateX(0.5rem);
            }
        }
    `}
`;
export const Wrapper = styled.div`
    ${({ theme }) => css`
        margin-left: 1.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media ${theme.breakpoints.large.media} {
            margin-left: unset;
            margin-top: 2rem;
        }
    `}
`;

export const CardLink = styled(BaseLink)`
    ${({ theme }) => css`
        color: var(--text-color, ${theme.colors.accents[4].hex});
        font-family: ${theme.fonts.secondary};
        font-weight: 300;
        font-size: 1.375rem;
        text-decoration: none;

        @media ${theme.breakpoints.medium.media} {
            font-size: 1.75rem;
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
    `}
`;
